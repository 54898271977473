/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';

// import App from './Component/Content/App';
import MobileNavigation from "./bricks/navigation/mobile-navigation";
import Tours from "./bricks/tours";
import Highlights from "./bricks/highlights";

/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        // App.initialize();
        MobileNavigation.initialize();
        Tours.initialize();
        Highlights.initialize();
    }

}

export default Application;