import Component from "../../../../../shared/js/common/component";
import Mmenu from 'mmenu-js/src/mmenu.js';

export default class MobileNavigation extends Component {
    static readonly componentName: string = "mobile-navigation";

    mmenu: Mmenu;

    init(): void {
        this.registerEvents();
    }

    registerEvents() {
        if(window.matchMedia('(max-width: 991px)').matches) {
            this.initMobileNav();
        }

        window.onresize = () => {
            if(window.matchMedia('(max-width: 991px)').matches) {
                this.initMobileNav();
            }
        }
    }

    initMobileNav() {
        let languageSwitch = document.querySelector('.language-switch').innerHTML;
        let languageSwitchHtml = '<div class="">'+languageSwitch+'</div>';
        let mobilebutton = document.querySelector('#mobilebutton');

        document.addEventListener("DOMContentLoaded", () => {
            this.mmenu = new Mmenu('#mobile-navigation', {
                "navbars": [
                    {
                        position: 'top',
                        content: [
                            'prev',
                            'title',
                            'close'
                        ]
                    },
                    {
                        position: 'bottom',
                        content: [
                            languageSwitchHtml
                        ]
                    }
                ],
                "offCanvas": {
                    pageSelector: '#siteWrapper',
                    position: 'left-front',
                },
                hooks: {
                    'open:before': () => {
                        setTimeout(() => {
                            mobilebutton.classList.add('is-active');
                        }, 100);
                    },
                    'close:before': () => {
                        setTimeout(() => {
                            mobilebutton.classList.remove('is-active');
                        }, 100);
                    }
                },
                "extensions": [
                    "theme-white",
                    "pagedim-black"
                ],
            });

            this.navigationEvents();
        })
    }

    navigationEvents() {
        let mmenuApi = this.mmenu.API;
        let mobilebutton = document.querySelector('#mobilebutton');

        mobilebutton.addEventListener("click", () => {
            if (mmenuApi) {
                mmenuApi.open();
            }
        });
    }

}