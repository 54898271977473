import Component from "../../../../shared/js/common/component";
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class Tours extends Component {

    static readonly componentName: string = "tours";
    swiper: Swiper;

    init() {
        if(window.matchMedia('(max-width: 991px)').matches) {
            this.initSlider();
        }

        window.onresize = () => {
            if(window.matchMedia('(max-width: 991px)').matches) {
                this.initSlider();
            }
        }
    }

    initSlider() {
        this.swiper = new Swiper('.swiper', {
            loop: true,
            slidesPerView: 1.25,
            centeredSlides: true,
        })
        this.swiper.slidePrev();
    }

}