import Component from '../../../../shared/js/common/component';
import Swiper from 'swiper';
import {Navigation, Controller, EffectFade, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class Highlights extends Component {
    static readonly componentName: string = 'b-highlights';
    swiper: Swiper;
    imageSwiper: Swiper;
    mobileSwiper: Swiper;

    init() {
        this.initSliders();
        this.initList('.stop-list', '.stop-list-item');
        this.initList('.mobile-stop-list', '.mobile-stop-list-item');
    }

    initSliders() {
        Swiper.use([Navigation, Controller, EffectFade, Autoplay]);

        this.imageSwiper = new Swiper('.image-slider', {
            slidesPerView: 1,
            effect: 'fade',
            direction: 'vertical',
            autoplay: {
                delay: 3000
            },
            fadeEffect: {
                crossFade: true
            },
            on: {
                slideChange: () => {
                    this.updateList(this.imageSwiper.activeIndex, this.imageSwiper.previousIndex)
                }
            }
        })
        this.mobileSwiper = new Swiper('.mobile-headlines', {
            slidesPerView: 1,
            effect: 'fade',
            centeredSlides: true,
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true
            },
        })
        this.imageSwiper.controller.control = this.mobileSwiper;
        this.mobileSwiper.controller.control = this.imageSwiper;
    }

    initList(stopListClass: string, stopListItemClass: string) {
        const stopList = document.querySelector(stopListClass);
        const stopListItems = stopList.querySelectorAll(stopListItemClass);
        stopListItems.forEach((item, i) => {
            item.addEventListener('click', () => this.imageSwiper.slideTo(i));
        });
    }

    updateList(index: number, previousIndex: number) {
        let stopList = document.querySelector('.stop-list');
        let stopListItems = stopList.querySelectorAll('.stop-list-item');
        stopListItems.item(index).classList.add('item-active');
        stopListItems.item(previousIndex).classList.remove('item-active');

        let mobileStopList = document.querySelector('.mobile-stop-list');
        let mobileStopListItems = mobileStopList.querySelectorAll('.mobile-stop-list-item');
        mobileStopListItems.item(index).classList.add('item-active');
        mobileStopListItems.item(previousIndex).classList.remove('item-active');
    }
}